import tw from 'twin.macro';

export const SectionContainer = tw.div`flex flex-col items-start max-w-4xl px-8`;

export const ImpressumHeader = tw.div`font-display text-base tracking-wide`;

export const Title = tw.h2`font-display text-3xl mb-4 font-bold`;

export const SubTitle = tw.h3`w-full font-display text-2xl mb-4`;

export const Link = tw.a`text-blue-700 underline`;
